import React from 'react'
import Header from './compenent/header'
import Col from './compenent/Col'

function Page2() {
  return (
    <div className='page2'>
      <Header/>
        <Col/>      
    </div>
  )
}

export default Page2
